import JRCore from 'jamespot-react-core';
import React from 'react';
import { MealReservationAppConst } from './MealReservation.const';
import { initGlobalAuth } from 'react-morel-auth';
import { AppEnv } from '../../utils/constant';
import { Theme } from 'jamespot-react-components';

const MealReservationApp = React.lazy(() => import(/* webpackChunkName: "DemoApp" */ './MealReservation.app'));

JRCore.extensionAdd(
  MealReservationAppConst.extensionName,
  () => import(/* webpackChunkName: "GroupCreate" */ './MealReservation.app'),
);

initGlobalAuth(
  {
    setItem: (key: string, value: string) => {
      localStorage.setItem(key, value);
    },
    getItem: (key: string) => {
      return localStorage.getItem(key);
    },
    removeItem: (key: string) => localStorage.removeItem(key),
  },
  {
    authApiUrl: AppEnv.REACT_APP_APIAUTH_URL,
    toolsApiUrl: AppEnv.REACT_APP_APITOOLS_URL,
  },
  '',
);

JRCore.router.addRoute({
  path: MealReservationAppConst.route,
  element: (
    <React.Suspense fallback={<></>}>
      <MealReservationApp />
    </React.Suspense>
  ),
});
