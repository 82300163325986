// export enum AppEnv {
//   REACT_APP_ENV = 'DEV',
//   REACT_APP_APIAUTH_URL = 'https://api-auth-test.morelonline.com',
//   REACT_APP_APIAUTH_USER = 'com.hellomorel',
//   REACT_APP_APIAUTH_KEY = '9xsvGNrJqGNc4TFWbUUwmusZK3nmneHcYjAjurV2upg4r9YqeGsmjDfbsXjq3GeW',
//
//   REACT_APP_APITOOLS_URL = 'https://eu-api-tools-test.morelonline.com',
//
//   REACT_APP_STRIPE_KEY = 'pk_test_51LBatYHphlBfrCuA93aj2BKUbp4MwdZ2F2rtutsZsio9VGse1W05XhM2nThvfh4fIqMm0E6ZMke6Dl8EupHHTVhR00qfdnST8o',
//   REACT_APP_STRIPE_BANK_ACCOUNT_NAME = 'trYeG2ZDFUfvC8pSNyWNbB7QaZCWVS2W',
// }

export enum AppEnv {
  REACT_APP_APIAUTH_URL = 'https://api-auth.morelonline.com',
  REACT_APP_ENV = 'PROD',
  REACT_APP_APIAUTH_USER = 'com.hellomorel',
  REACT_APP_APIAUTH_KEY = 'mZwAQGKtQM3yDYqNsjcATM6vkKJaycM6G4WCNgJgnU3cV7xyfFu3Q46N6NKARQTP',
  REACT_APP_APITOOLS_URL = 'https://eu-api-tools.morelonline.com',
  REACT_APP_STRIPE_KEY = 'pk_live_51LBatYHphlBfrCuAN0efgXphTbkP9cWFoSx3z720Cf8iGsqzGbF5ogLRHRhUmhrcmOrdMnNZQJTyjOBHcKhssvdq00bRPFO2kh',
  REACT_APP_STRIPE_BANK_ACCOUNT_NAME = 'trYeG2ZDFUfvC8pSNyWNbB7QaZCWVS2W',
}
export const MEAL_UNIT_PRICE = 4.20;
